@font-face {
    font-family: Poppins;
    src: url("../fonts/Poppins.ttf");
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: Poppins, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

#shell_content_fit {
    min-height: calc(100vh - 4rem - 11rem);
}

.ant_hover:hover,
.ant_hover:hover path {
    fill: #69b1ff;
    transition: all 0.25s;
}

.invert_hover:hover img {
    filter: invert();
    transition: all 0.5s;
}

.invert_hover:hover svg {
    filter: invert();
    transition: all 0.15s;
}

.animate-invert {
    animation: invertAnimation 15s infinite;
}

.ant-card-head {
    padding: 0px 12px !important;
}

.ant-card-body {
    padding: 12px !important;
}

@keyframes invertAnimation {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(-180deg);
    }

    100% {
        filter: hue-rotate(0deg);
    }
}

.layout {
    width: 90vw;
    aspect-ratio: 9 / 16;
}

.layout-0 .ant-upload-photobooth-1 .ant-upload-select,
.photobooth-1 {
    left: 5.55%;
    top: 3.12%;
}

.layout-0 .ant-upload-photobooth-2 .ant-upload-select {
    right: 5.55%;
    top: 3.12%;
}

.layout-0 .ant-upload-photobooth-3 .ant-upload-select {
    bottom: 28.12%;
    left: 5.55%;
}

.layout-0 .ant-upload-photobooth-4 .ant-upload-select {
    bottom: 28.12%;
    right: 5.55%;
}

.layout-0 .ant-upload-photobooth-5 .ant-upload-select {
    bottom: 3.12%;
    left: 5.55%;
}

.layout-1 .ant-upload-photobooth-1 .ant-upload-select {
    left: 5.55%;
    top: 3.12%;
}

.layout-1 .ant-upload-photobooth-2 .ant-upload-select {
    bottom: 3.12%;
    left: 5.55%;
}

.layout-1 .ant-upload-photobooth-3 .ant-upload-select {
    bottom: 3.12%;
    right: 5.55%;
}

.layout-1 .ant-upload-photobooth-4 .ant-upload-select {
    top: 3.12%;
    right: 5.55%;
}

.layout-1 .ant-upload-photobooth-5 .ant-upload-select {
    top: 29.68%;
    left: 5.55%;
}

.layout-2 .ant-upload-photobooth-1 .ant-upload-select {
    top: 28.12%;
    left: 5.55%;
}

.layout-2 .ant-upload-photobooth-2 .ant-upload-select {
    top: 28.12%;
    right: 5.55%;
}

.layout-2 .ant-upload-photobooth-3 .ant-upload-select {
    bottom: 3.12%;
    left: 5.55%;
}

.layout-2 .ant-upload-photobooth-4 .ant-upload-select {
    bottom: 3.12%;
    right: 5.55%;
}

.layout-2 .ant-upload-photobooth-5 .ant-upload-select {
    top: 3.12%;
    left: 5.55%;
}

.ant-upload-hidden .ant-upload {
    border: none !important;
}

.ant-upload-photobooth {
    width: 100%;
    height: auto;
}

.ant-upload-photobooth-1 .ant-upload-select,
.photobooth-1 {
    width: 41.66% !important;
    height: 23.43% !important;
    position: absolute;
}

.ant-upload-photobooth-2 .ant-upload-select {
    width: 41.66% !important;
    height: 41.68% !important;
    position: absolute;
}

.ant-upload-photobooth-3 .ant-upload-select {
    width: 41.66% !important;
    height: 41.68% !important;
    position: absolute;
}

.ant-upload-photobooth-4 .ant-upload-select {
    width: 41.66% !important;
    height: 23.43% !important;
    position: absolute;
}

.ant-upload-photobooth-5 .ant-upload-select {
    width: 88.88% !important;
    height: 21.87% !important;
    position: absolute;
}

.boothPatternOne {
    background-image: url("../sprites/images/SanctaMaria/BoothBg1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.boothPatternTwo {
    background-image: url("../sprites/images/SanctaMaria/BoothBg2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.boothPatternThree {
    background-image: url("../sprites/images/SanctaMaria/BoothBg3.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.boothPatternFour {
    background-image: url("../sprites/images/SanctaMaria/BoothBg4.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.boothPatternFive {
    background-image: url("../sprites/images/SanctaMaria/BoothBg5.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* .boxshadow {
    box-shadow: 0px 0px 10px #cfcfcf;
} */